import React from 'react'
import PropTypes from 'prop-types'

const WorkCard = ({title, image, description, link}) => {
  return(
    <React.Fragment>
      <a href={link} target={"_blank"}>
      <div className="title has-text-white has-text-weight-bold">
        {title}
      </div>
      <img
        className="work-image"
        src={image}
      />
      <p className="has-text-white work-image">
        {description}
      </p>
      </a>
    </React.Fragment>
  )
}

WorkCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
}

export default WorkCard