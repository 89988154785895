import React from "react";
import Template from "../../components/Template";
import Footer from '../../components/Footer'
import '../../components/test.sass'
import WorkCard from "../../components/WorkCard";

type Work = {
  title: string,
  img: string,
  description: string,
  link: string
}

const works: Work[] = [
  {
    title: 'Automating Customer Centric Influencer Marketing at Scale',
    img: '/images/ib_logo.png',
    description: '',
    link: 'https://influencerbit.com'
  },
  // {
  //   title: 'Social Engagement Tools',
  //   img: '/images/web3.png',
  //   description: 'Auto Engagement tools for Social Networks which organically grow your social profiles',
  //   link: '#'
  // },
  // {
  //   title: 'Beatshine',
  //   img: '/images/web2.png',
  //   description: 'Tool to grow your Beatstars account and increase your fanbase on Beatstars. Auto Engages with people and boosts your track plays',
  //   link: '#'
  // }
]

export default function Work() {
  return (
  <Template
    activeTab={2}
    topPaddingLess
    title="Work"
  >
    <div className="container">
      <div className="columns is-multiline">
        {works.map((item, index) => (
          <div className="column is-half-tablet is-full-mobile mb-3" key={index}>
            <WorkCard 
              title={item.title}
              description={item.description}
              image={item.img}
              link={item.link}
            />
          </div>
        ))}
      </div>
      <Footer />
    </div>
  </Template>
  )
}